import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import DownloadLink from "@components/downloadLink"

function Meals({ data }) {
  return (
    <Layout>
      <SEO title="Dożywianie - ZSP Nidek" />
      <h1>Dożywianie</h1>
      <DownloadLink data={data} strapiCollection="allStrapiZywienieSzkola">
        Informacja o opłatach za żywienie
      </DownloadLink>
    </Layout>
  )
}

export default Meals

export const query = graphql`
  query MealsQuery {
    allStrapiZywienieSzkola {
      edges {
        node {
          plik_zywienie_szkola {
            ext
            id
            name
            size
            url
          }
        }
      }
    }
  }
`
